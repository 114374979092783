import React, { useEffect, useRef, useCallback, useState } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { 
  Button, VStack, Text, Box, Container, Heading, Flex, Spacer, 
  useColorModeValue, Badge, Icon, Tooltip
} from '@chakra-ui/react';
import { FaMicrophone, FaStop } from 'react-icons/fa';

// Define the types here if they're not exported by the package
interface ConversationItem {
  id: string;
  role: string;
  type: string;
  formatted: {
    text?: string;
    [key: string]: any;
  };
  [key: string]: any;
}

interface ConversationDelta {
  audio?: Int16Array;
  [key: string]: any;
}

const OPENAI_API_KEY: string = process.env.REACT_APP_OPENAI_API_KEY || '';

// Function to convert ItemType to ConversationItem
const convertToConversationItem = (item: ItemType): ConversationItem => {
  const { id, role, type, formatted, ...rest } = item;
  return {
    id: id || '',
    role: role || '',
    type: type || '',
    formatted: formatted || {},
    ...rest
  };
};

const ConsolePage: React.FC = () => {
  const [items, setItems] = useState<ConversationItem[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef<RealtimeClient | null>(null);

  const connectConversation = useCallback(async () => {
    const client = new RealtimeClient({
      apiKey: OPENAI_API_KEY,
      dangerouslyAllowAPIKeyInBrowser: true,
    });
    clientRef.current = client;

    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    setIsConnected(true);
    setItems(client.conversation.getItems().map(convertToConversationItem));

    await wavRecorder.begin();
    await wavStreamPlayer.connect();
    await client.connect();

    client.sendUserMessageContent([
      {
        type: 'input_text',
        text: 'Hello!',
      },
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }

    client.on('conversation.updated', async ({ item, delta }: { item: ItemType, delta: ConversationDelta }) => {
      console.log('Conversation updated:', item, delta);
      setItems(prevItems => {
        const newItems = [...prevItems];
        const existingItemIndex = newItems.findIndex(i => i.id === item.id);
        if (existingItemIndex !== -1) {
          newItems[existingItemIndex] = convertToConversationItem(item);
        } else {
          newItems.push(convertToConversationItem(item));
        }
        return newItems;
      });
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id || '');
      }
    });
  }, []);

  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setItems([]);

    const client = clientRef.current;
    if (client) {
      client.disconnect();
    }

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client?.cancelResponse(trackId, offset);
    }

    await wavRecorder.record((data) => client?.appendInputAudio(data.mono));
  };

  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client?.createResponse();
  };

  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }

    client?.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });

    if (value === 'server_vad' && client?.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }

    setCanPushToTalk(value === 'none');
  };

  const renderConversation = (items: ConversationItem[]) => {
    return items.map((item, index) => {
      let role = item.role === 'assistant' ? 'A' : item.role;
      let content = '';
      
      if (item.type === 'message') {
        content = item.formatted.transcript || item.formatted.text || '';
      } else if (item.type === 'function_call' || item.type === 'function_call_output') {
        content = JSON.stringify(item.formatted);
      }

      if (content) {
        return (
          <Box 
            key={index} 
            bg={role === 'user' ? 'blue.800' : 'green.800'} 
            color="white" 
            p={4} 
            borderRadius="lg" 
            mb={3}
            boxShadow="md"
          >
            <Flex align="center" mb={2}>
              <Badge colorScheme={role === 'user' ? 'blue' : 'green'}>{role}</Badge>
              <Spacer />
              <Text fontSize="xs" color="gray.300">{new Date().toLocaleTimeString()}</Text>
            </Flex>
            <Text>{content}</Text>
          </Box>
        );
      }
      return null;
    });
  };

  const bg = "gray.900";
  const color = "gray.100";
  const buttonBg = "blue.500";
  const buttonColor = "white";

  return (
    <Container maxW="container.xl" minH="100vh" bg={bg} color={color} p={6}>
      <VStack spacing={8} align="stretch">
        <Flex align="center">
          <Heading size="lg" bgGradient="linear(to-r, cyan.400, blue.500, purple.600)" bgClip="text">
            OpenAI Realtime Console
          </Heading>
          <Spacer />
          <Tooltip label={isConnected ? "Disconnect" : "Connect"}>
            <Button 
              onClick={isConnected ? disconnectConversation : connectConversation} 
              bg={buttonBg} 
              color={buttonColor}
              _hover={{ bg: 'blue.600' }}
              size="lg"
              borderRadius="full"
            >
              {isConnected ? 'Disconnect' : 'Connect'}
            </Button>
          </Tooltip>
        </Flex>
        {isConnected && (
          <>
            <Flex>
              <Tooltip label={canPushToTalk ? "Enable Voice Activity Detection" : "Disable Voice Activity Detection"}>
                <Button 
                  onClick={() => changeTurnEndType(canPushToTalk ? 'server_vad' : 'none')} 
                  bg={buttonBg} 
                  color={buttonColor}
                  _hover={{ bg: 'blue.600' }}
                >
                  {canPushToTalk ? 'Enable VAD' : 'Disable VAD'}
                </Button>
              </Tooltip>
              <Spacer />
              {canPushToTalk && (
                <Tooltip label={isRecording ? "Release to Send" : "Push to Talk"}>
                  <Button
                    onMouseDown={startRecording}
                    onMouseUp={stopRecording}
                    bg={isRecording ? 'red.500' : 'green.500'}
                    color="white"
                    _hover={{ bg: isRecording ? 'red.600' : 'green.600' }}
                    size="lg"
                    borderRadius="full"
                    leftIcon={<Icon as={isRecording ? FaStop : FaMicrophone} />}
                  >
                    {isRecording ? 'Recording...' : 'Push to Talk'}
                  </Button>
                </Tooltip>
              )}
            </Flex>
            <Box 
              borderWidth={1} 
              borderRadius="xl" 
              p={6} 
              bg="gray.800"
              boxShadow="xl"
              maxHeight="60vh"
              overflowY="auto"
              borderColor="gray.700"
            >
              <Heading size="md" mb={4} color="blue.300">Conversation</Heading>
              {renderConversation(items)}
            </Box>
          </>
        )}
      </VStack>
    </Container>
  );
};

export default ConsolePage;
