import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import theme from './theme'; // You'll need to create this
import ConsolePage from './components/ConsolePage';
import './App.scss';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ConsolePage />
    </ChakraProvider>
  );
}

export default App;
